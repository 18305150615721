const tableConfig = {
  tags: {
    headings: [
      { name: 'Tag', classes: 'text-left' },
      { name: 'Unit', classes: 'text-left' },
      { name: 'Equipment', classes: 'text-left' },
      { name: 'Name', classes: 'text-left' },
      { name: 'Engineering Unit', classes: '' },
      { name: 'Min Value', classes: '' },
      { name: 'Max Value', classes: '' },
      { name: 'Min Range', classes: '' },
      { name: 'Max Range', classes: '' },
      { name: 'Pinned', classes: '' },
      { name: 'Actions', classes: 'text-center' }
    ],
    body: [
      { name: 'unitName', classes: '' },
      { name: 'equipmentName', classes: '' },
      { name: 'name', classes: '' },
      { name: 'uom', classes: 'text-center' },
      { name: 'minValue', classes: 'text-center' },
      { name: 'maxValue', classes: 'text-center' },
      { name: 'minRange', classes: 'text-center' },
      { name: 'maxRange', classes: 'text-center' }
    ],
    itemName: 'tagNameIdentifier',
    containerClasses: 'overflow-auto p-3',
    isSelectedItemShown: false,
    rowSingleTitleClasses: 'underline underline-offset-4',
  },
  expressions: {
    headings: [
      { name: 'Name', classes: 'text-left' },
      { name: 'Plant', classes: 'text-left' },
      { name: 'Train', classes: 'text-left' },
      { name: 'Unit', classes: 'text-left' },
      { name: 'Equipment', classes: 'text-left' },
      { name: 'Engineering Unit', classes: 'text-center' },
      { name: 'Seq.No.', classes: 'text-center' },
      { name: 'Min Value', classes: 'text-center' },
      { name: 'Max Value', classes: 'text-center' },
      { name: 'Min Range', classes: 'text-center' },
      { name: 'Max Range', classes: 'text-center' },
      { name: 'Actions', classes: 'text-center' }
    ],
    body: [
      { name: 'plantName', classes: '' },
      { name: 'trainName', classes: '' },
      { name: 'unitName', classes: '' },
      { name: 'equipmentName', classes: '' },
      { name: 'uom', classes: 'text-center' },
      { name: 'seqOrder', classes: 'text-center' },
      { name: 'minValue', classes: 'text-center' },
      { name: 'maxValue', classes: 'text-center' },
      { name: 'minRange', classes: 'text-center' },
      { name: 'maxRange', classes: 'text-center' }
    ],
    itemName: 'name',
    containerClasses: 'overflow-auto p-3',
    isSelectedItemShown: false,
    rowSingleTitleClasses: 'underline underline-offset-4',
  },
  trains: {
    headings: [
      { name: 'Train Name', classes: '' },
      { name: 'Description', classes: '' },
      { name: 'Seq.No.', classes: '' },
      { name: 'Actions', classes: '' }
    ],
    body: [
      { name: 'desc', classes: '' },
      { name: 'seqOrder', classes: '' },
    ],
    itemName: 'name',
    containerClasses: '',
    isSelectedItemShown: true,
    rowSingleTitleClasses: 'underline underline-offset-4',
  },
  units: {
    headings: [
      { name: 'Unit Name', classes: '' },
      { name: 'Description', classes: '' },
      { name: 'Seq.No.', classes: '' },
      { name: 'Actions', classes: '' }
    ],
    body: [
      { name: 'desc', classes: '' },
      { name: 'seqOrder', classes: '' },
    ],
    itemName: 'name',
    containerClasses: '',
    isSelectedItemShown: true,
    rowSingleTitleClasses: 'underline underline-offset-4',
  },
  equipments: {
    headings: [
      { name: 'Equipment Name', classes: '' },
      { name: 'Description', classes: '' },
      { name: 'Seq.No.', classes: '' },
      { name: 'Actions', classes: '' },
    ],
    body: [
      { name: 'desc', classes: '' },
      { name: 'seqOrder', classes: '' },
    ],
    itemName: 'name',
    containerClasses: '',
    isSelectedItemShown: true,
    rowSingleTitleClasses: 'underline underline-offset-4',
  },
  usersAdmin: {
    headings: [
      { name: 'Name', classes: '' },
      { name: 'Phone Number', classes: '' },
      { name: 'Email', classes: '' },
      { name: 'Role', classes: '' }
    ],
    body: [
      { name: 'phoneNumber', classes: '' },
      { name: 'email', classes: '' },
    ],
    itemName: 'name',
    containerClasses: 'overflow-auto p-3 mb-2',
    isSelectedItemShown: false,
    rowSingleTitleClasses: '',
  },
  usersNotAdmin: {
    headings: [
      { name: 'Name', classes: '' },
    ],
    body: [
    ],
    itemName: 'name',
    containerClasses: 'overflow-auto p-3 mb-2',
    isSelectedItemShown: false,
    rowSingleTitleClasses: '',
  },
  tickets: {
    headings: [
      { name: 'Tag Name', classes: '' },
      { name: 'Title', classes: '' },
      { name: 'Priority', classes: '' },
      { name: 'Severity', classes: '' },
      { name: 'Status', classes: '' },
      { name: 'Entry Date', classes: '' },
      { name: 'Actions', classes: 'text-center' },
    ],
    body: [
      { name: 'title', classes: '' },
      { name: 'priority', classes: '' },
      { name: 'severity', classes: '' },
      { name: 'statusId', classes: '' },
      { name: 'createdOn', classes: '' },
    ],
    itemName: 'tagName',
    containerClasses: 'overflow-auto p-3',
    isSelectedItemShown: false,
    rowSingleTitleClasses: '',
  },
  alarms: {
    headings: [
      { name: 'Tag Name', classes: '' },
      { name: 'Tag Description', classes: '' },
      { name: 'Entry Date', classes: '' },
      { name: 'Status', classes: '' },
      { name: 'Actions', classes: 'text-center' },
    ],
    body: [
      { name: 'tagName', classes: '' },
      { name: 'createdOn', classes: '' },
      { name: 'statusId', classes: '' },
    ],
    itemName: 'tagNameIdentifier',
    containerClasses: 'overflow-auto',
    isSelectedItemShown: false,
    rowSingleTitleClasses: 'underline underline-offset-4',
  },
  health: {
    headings: [
      { name: 'Name', classes: '' },
      { name: 'Description', classes: '' },
      { name: 'Status', classes: '' },
      { name: 'Message', classes: '' },
      { name: 'Last Updated', classes: '' },
      { name: 'Retry', classes: '' },
    ],
    body: [
      { name: 'desc', classes: '' },
      { name: 'status', classes: '' },
      { name: 'message', classes: '' },
      { name: 'updateTime', classes: '' },
    ],
    itemName: 'name',
    containerClasses: 'overflow-auto',
    isSelectedItemShown: false,
    rowSingleTitleClasses: '',
  }
};

export default tableConfig;