import React, { useEffect, useState } from "react";
import tableConfig from "./config/tableConfig";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "@/components/ui/table";
import { DataTableProps } from "./domain/types";
import { Item } from "types/common";
import classNames from "utilities/ClassNames";
import TagsActions from "./tableActions/TagsActions/TagsActions";
import { Checkbox } from "@/components/ui/checkbox";
import RoleDropdown from "pages/users/RoleDropdown";
import { getRoles } from "api/users";
import useApp from "hooks/useApp";

function sortItems(items: any, targetEquipmentsIds: any) {
  return items.sort((a: any, b: any) => {
    const aSelected = targetEquipmentsIds.includes(a.id);
    const bSelected = targetEquipmentsIds.includes(b.id);

    if (aSelected && !bSelected) return -1;
    if (!aSelected && bSelected) return 1;
    return 0;
  });
}

const DataTable: React.FC<DataTableProps> = ({ type, rows, hasPins, hasActions, onClickHandler, onActionClickHandler, setSelectedPage, setMappedTags, mappedTags, selectedPage, special, selectedItem, hasRoles, refresh }) => {
  const { userRole } = useApp();
  const { headings, body, itemName, containerClasses, isSelectedItemShown, rowSingleTitleClasses } = tableConfig[type];
  const [processedHeadings, setProcessedHeadings] = useState(headings);
  const [rowsForEquipment, setRowsForEquipment] = useState<any>([]);
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [sortedRows, setSortedRows] = useState(rows);
  const [selectedUnderlineItem, setSelectedUnderlineItem] = useState<any>(null);
  const [rolesData, setRolesData] = useState<any>();
  const tableContainerClasses = classNames(special ? "bg-dark" : "bg-black", "rounded-md h-full", containerClasses);
  const rowTitleClasses = classNames(special ? "" : "cursor-pointer", "hover:text-green p-3", rowSingleTitleClasses);

  useEffect(() => {
    const noPinsHeadings = headings.filter((single) => single.name !== 'Pinned');
    const noActionsHeadings = headings.filter((single) => single.name !== 'Actions');
    const noActionsAndPinsHeadings = noActionsHeadings.filter((single) => single.name !== 'Pinned');
    if (!hasPins && hasActions) {
      setProcessedHeadings(noPinsHeadings);
    }
    if (!hasActions && hasPins) {
      setProcessedHeadings(noActionsHeadings);
    }
    if (!hasActions && !hasPins) {
      setProcessedHeadings(noActionsAndPinsHeadings);
    }
  }, []);

  useEffect(() => {
    if (selectedItem) {
      setSelectedUnderlineItem(selectedItem.id);
    }
    else {
      setSelectedUnderlineItem(null);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (special) {
      const mappedRows = sortItems(rows, rowsForEquipment);
      setSortedRows(mappedRows);
    }
    if (!special) {
      setSortedRows(rows);
    }
  }, [rowsForEquipment, rows]);

  useEffect(() => {
    if (userRole !== 'admin') {
      setIsEditDisabled(true);
    }
  }, [userRole]);

  const isSelected = (tag: any) => {
    return rowsForEquipment.some((row: any) => row === tag.id);
  };

  useEffect(() => {
    const getRolesApi = async () => {
      const resp2 = await getRoles();
      if (resp2 && resp2.status === 200) {
        setRolesData(resp2.data);
      }
    };

    getRolesApi();
  }, [rows]);

  const onClickSpecialHandler = (item: Item) => {
    if (rowsForEquipment.some((row: any) => row === item.id)) {
      if (setMappedTags) {
        if (mappedTags.added.includes(item.id)) {
          setMappedTags((prev: any) => ({
            added: [...prev.added.filter((addedItem: any) => addedItem !== item.id)],
            removed: [...prev.removed],
          }));
        }
        if (!mappedTags.added.includes(item.id)) {
          setMappedTags((prev: any) => ({
            added: [...prev.added],
            removed: [...prev.removed, item.id],
          }));
        }
      }
      const newRows = rowsForEquipment.filter((row: any) => row !== item.id);
      setRowsForEquipment(newRows);
    } else {
      if (setMappedTags) {
        if (mappedTags.removed.includes(item.id)) {
          setMappedTags((prev: any) => ({
            added: [...prev.added],
            removed: [...prev.removed.filter((removedItem: any) => removedItem !== item.id)],
          }));
        }
        if (!mappedTags.removed.includes(item.id)) {
          setMappedTags((prev: any) => ({
            added: [...prev.added, item.id],
            removed: [...prev.removed],
          }));
        }
      }
      const newRows = [...rowsForEquipment, item.id];
      setRowsForEquipment(newRows);
    }
  };

  return (
    <div className={tableContainerClasses}>
      <Table className="w-full border-collapse">
        <TableHeader className="text-green">
          <TableRow className="weigh no-underline border-none">
            {processedHeadings.map((heading, index) => (
              <TableHead key={index} className={classNames(heading.classes, 'font-bold text-[15px] xl:text-[17px] 2xl:text-xl')}>
                {heading.name}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedRows && sortedRows.map((item: any, rowIndex) => (
            <TableRow className={classNames(item.statusId === 'New' ? "bg-lightRed" : "", "border-b border-b-lightGray hover:bg-dark duration-300")} key={rowIndex}>
              <TableCell
                onClick={() => {
                  special ? onClickSpecialHandler(item) : onClickHandler(item, 'chart');
                }}
                className={classNames(
                  isSelectedItemShown ? item.id === selectedUnderlineItem ? "text-orange" : "" : "", rowTitleClasses, "text-[15px] xl:text-[16px] 2xl:text-xl h-min p-2.5 2xl:p-3")}
              >
                {special ? (
                  <>
                    <Checkbox
                      checked={isSelected(item)}
                      aria-label="Select row"
                      style={{ color: isSelected(item) ? 'cyan' : '' }}
                    />
                    <span className="underline cursor-pointer ml-2">{item[itemName as keyof Item]}</span>
                  </>
                ) : (
                  item[itemName as keyof Item]
                )}
              </TableCell>
              {body.map((single, cellIndex) => {
                return (
                  <TableCell key={cellIndex} className={classNames(
                    item[single.name] === 'Healthy' ? 'text-limeGreen' : '',
                    item[single.name] === 'Failed' ? 'text-red' : '',
                    item[single.name] === 'Warning' ? 'text-orange' : '',
                    single.name === 'message' ? 'text-lightBlue' : '',
                    single.classes,
                    "text-[15px] xl:text-[16px] 2xl:text-xl"
                  )}>
                    {item[single.name as keyof Item]}
                  </TableCell>
                );
              })}

              {hasActions && (
                <TableCell>
                  {type === 'tags' && <TagsActions type="tag" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                  {type === 'expressions' && <TagsActions type="expression" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                  {type === 'trains' && <TagsActions type="train" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                  {type === 'units' && <TagsActions type="unit" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                  {type === 'equipments' && <TagsActions type="equipment" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                  {type === 'tickets' && <TagsActions type="tickets" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                  {type === 'alarms' && <TagsActions type="alarms" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                  {type === 'health' && <TagsActions type="health" setAction={onActionClickHandler} itemValue={item} setSelectedPage={setSelectedPage} />}
                </TableCell>
              )}
              {hasRoles && (
                <TableCell>
                  <div>
                    <RoleDropdown
                      id={item.id}
                      currentrole={
                        item.role
                          ? item.role.charAt(0).toUpperCase() +
                          item.role.slice(1)
                          : item.role
                      }
                      rolesData={rolesData}
                      refreshhandler={refresh}
                    />
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default DataTable;
