import { LuFileEdit, LuTimerReset, LuTrash2 } from "react-icons/lu";
import { FiXSquare } from "react-icons/fi";
import { ActionName } from "types/common";
import { FiRefreshCw } from "react-icons/fi";

const tagsActionsConfig = (iconSize: any) => ({
  tag: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'reset', actionName: 'reset' as ActionName, icon: <LuTimerReset size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  expression: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  train: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  unit: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  equipment: [
    { name: 'edit', actionName: 'edit' as ActionName, icon: <LuFileEdit size={iconSize} />, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 size={iconSize} />, danger: true }
  ],
  tickets: [
    { name: 'reject', actionName: 'reject' as ActionName, icon: <FiXSquare className="text-red" size={iconSize} />, danger: true },
    { name: 'close', actionName: 'close' as ActionName, icon: <span className="btn-cursordefault hover:text-white">Close</span>, danger: false },
    { name: 'delete', actionName: 'delete' as ActionName, icon: <LuTrash2 className="text-red" size={iconSize} />, danger: true }
  ],
  alarms: [
    { name: 'Acknowledge', actionName: 'acknowledge' as ActionName, icon: <span className="btn-cursordefault hover:text-white">Acknowledge</span>, danger: false },
    { name: 'Delete', actionName: 'delete' as ActionName, icon: <span className="btn-sec hover:text-red flex justify-center items-center"><LuTrash2 size={iconSize} className="mr-1" />Delete</span>, danger: true },
  ],
  health: [
    { name: 'Refresh', actionName: 'refresh' as ActionName, icon: <FiRefreshCw size={iconSize} className="mt-1.5 cursor-pointer" color="cyan" />, danger: false },
  ]
});

export default tagsActionsConfig;
