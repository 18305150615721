import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useScreen from "hooks/useScreen";
import { useState } from "react";
import { FiChevronDown, FiUser } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Profile from "../profile/profile";
import { useAuth } from "react-oidc-context";
import useApp from "hooks/useApp";
import { cn } from "@/lib/utils";

export default function UserMenu() {
  const navigate = useNavigate();
  const { user } = useApp();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const { isScaled } = useScreen();


  const auth = useAuth();
  const logout = async () => auth.signoutRedirect();

  const healthsensor = () => {
    navigate("/health");
  };

  const dropdownItems = [
    {
      label: "Profile",
      onClick: () => setShowProfileMenu(true),
    },
    {
      label: "Health Sensor",
      onClick: healthsensor,
    },
    {
      label: "Logout",
      onClick: logout,
    },
  ];

  return (
    <>
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className={cn("lg:gap-0 xl-2xl:gap-1 2xl:gap-2", "flex items-center cursor-pointer")}>
              {!user?.avatar && (
                <div className="gradient p-1 rounded-full">
                  <div className={cn("lg:w-5 lg:h-5 xl-2xl:w-6 xl-2xl:h-6 2xl:w-7 2xl:h-7", " grid place-content-center bg-dark rounded-full")}>
                    <FiUser size={20} />
                  </div>
                </div>
              )}
              {user?.avatar && (
                <div className="gradient p-1 rounded-full">
                  <Avatar className="w-7 h-7">
                    <AvatarImage
                      src={`data:image/jpeg;base64,${user?.avatar}`}
                      alt="@shadcn"
                    />
                    <AvatarFallback></AvatarFallback>
                  </Avatar>
                </div>
              )}
              <div className="flex items-center gap-2 font-bold whitespace-nowrap">
                <span className="capitalize">{user?.firstName}</span>
                <span className="capitalize">{user?.lastName}</span>
              </div>
              <div className="mt-1">
                <FiChevronDown />
              </div>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-48 bg-dark mt-3 border-slate">
            <DropdownMenuGroup>
              {dropdownItems.map((item, index) => (
                <DropdownMenuItem
                  key={index}
                  className="cursor-pointer hover:bg-slate"
                  onClick={item.onClick}
                >
                  {item.label}
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <Profile
          closeMenu={() => setShowProfileMenu(false)}
          showProfileMenu={showProfileMenu}
        />
      </>
    </>
  );
}
