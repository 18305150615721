import React, { useEffect, useState } from "react";
import ActionItem from "./ActionItem";
import tagsActionsConfig from "../config/tagsActionsConfig";
import { TagsActionsProps } from "../domain/types";
import { useMediaQuery } from "@mui/material";

const TagsActions: React.FC<TagsActionsProps> = ({ type, setAction, itemValue, setSelectedPage }) => {
  const isBigScreen = useMediaQuery('(min-width:1800px)');
  const isMidScreen = useMediaQuery('(min-width:13660px)');

  const iconSize = isMidScreen ? 17 : isBigScreen ? 20 : 15;
  const actionEntries = tagsActionsConfig(iconSize)[type];

  const genActionLabelForAlarms = (item: any, singleEntry: any) => {
    if (singleEntry.actionName === 'delete') {
      return 'Delete';
    }
    if (item.statusId === 'New') {
      return 'Acknowledge';
    }
    if (item.statusId === 'Acknowledged') {
      return 'Open';
    }
    return '';
  };

  const genActionIconForAlarms = (item: any, singleEntry: any) => {
    console.log(item, singleEntry);
    if (item.statusId === 'Acknowledged' && singleEntry.actionName === 'acknowledge') {
      return (<span className="btn-cursordefault hover:text-white">Open</span>);
    }
    return singleEntry.icon;
  };

  return (
    <div className="flex w-full gap-5 items-center justify-center">
      {actionEntries.map((single, key) => (
        <ActionItem
          key={key}
          actionName={single.actionName}
          item={itemValue}
          text={type === 'alarms' ? genActionLabelForAlarms(itemValue, single) : single.name}
          icon={type === 'alarms' ? genActionIconForAlarms(itemValue, single) : single.icon}
          danger={single.danger}
          action={setAction}
          type={type}
          setSelectedPage={setSelectedPage}
          size={iconSize}
        />
      ))}
    </div>
  );
};

export default TagsActions;
